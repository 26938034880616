/* TYPE */

/* html, body, .window, .content, .board {
  box-sizing: border-box;
} */

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;

  display: flex;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration-line: none;
}

h1 h2, h3, p {
  margin: 0;
}


/* GENERAL */

.window {
  display: grid;
  grid-template-rows: auto;
}


/* NAVIGATION */

.navigation {
  position: fixed;
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  backdrop-filter: blur(3px);
  border-bottom: 1px solid rgba(203, 203, 203, 0.4);
  padding-top: 8px;
  padding-bottom: 8px;
}

.nav-snbd-icon {
  width: 45px;
  height: 45px;
  margin-left: 16px;
  border-radius: 8pt;
  border: 1px solid rgba(88, 88, 88, 0.3);
}

.nav-title {
  font-size: 22px;
  font-weight: 700;
  padding-left: 8pt;
  color: black;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.info-button {
  margin-left: 4pt;
  margin-right: 4pt;
  padding-left: 4px;
  padding-right: 4pt;
  /* border: none;
  background-color: transparent; */
  cursor: pointer;
}

.align-trail-container {
  display: flex;
  justify-content: right;
}

.downloadLink {
  display: flex;
  align-items: center;
  justify-content: center;
}

.get-snbd-button {
  background-color: black;
  color: white;
  font-size: 17px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
}

.downloadOnAppStoreImage {
  margin-right: 16px;
  width: 100px;
  height: 40px;
}


/* CONTENT */

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  z-index: 1;
}

/* Stops scrolling behind info modal */
.limit-scroll {
  height: 90vh;
  overflow-y: hidden;
}

.centered-content {
  display: flex;
  justify-content: center;
  width: 100vw;
}

/* HOME */

.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  margin-top: 67px;
}

.hero {
  width: 65vw;
  max-width: 700px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.hero-container-v {
  /* padding: 16px; */
  padding-top: 83px;
  padding-bottom: 40px;
  /* background-color: purple; */
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
  align-items: center;
  /* align-items: center; */
}

.leading-hero-v {
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
}

.trailing-hero-v {
  width: 90%;
  padding: 10px;
  
  /* padding: 10px; */
  /* background-color: brown; */
}

.hero-container-h {
  padding-top: 83px;
  padding-left: 16px;
  padding-bottom: 16px;
  /* background-color: yellow; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 50px;
  /* flex */
}

.leading-hero-h {
  display: flex;
flex-direction: column;
/* justify-content: space-between; */
padding-bottom: 40px;
gap: 14px;

  /* background-color: orange; */
  max-width: 400px;
  max-height: 400px;
  
}

.trailing-hero-h {
  /* background-color: red; */
  max-width: 800px;
}

.hero-title {
  font-size: min(6vw, 100px);
  margin: 0;
}

.hero-subtitle {
  /* font-size: 20px; */
  color: dimgray;
}

.hero-image {
  width: 100%;
  /* width:55vw; */
  /* max-width: 800px; */
}

.headline, .subheadline {
  text-align: center;
}

.subheadline {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 14px;
}

.-carousel-section {
  /* background-color: orange; */
  /* padding-left: 16px; */
  padding-bottom: 16px;
}

.carousel-content {
  display: flex;
  padding-bottom: 40px;
  overflow-x: scroll;
}

.carousel-item {
  margin-left: max(16px, 2.5vw);
  text-align: center;
}

.carousel-item:first-of-type {
  margin-left: 16px;
}

.carousel-item:last-of-type {
  padding-right: 16px;
}

.carousel-text {
  text-decoration: none;
  color: black;
}

.carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-image {
  width: 22vw;
  aspect-ratio: 1 / 1;
  max-width:150px;
  border-radius: 120pt;
  background-color: rgba(88, 88, 88, 0.3);
  border: 1px solid rgba(88, 88, 88, 0.3);
  margin-bottom: 5px;
}

.features {
  gap: 16px;
}

.outer-card:first-of-type {
  margin-left: 16px;
}

.outer-card:last-of-type {
  padding-right: 16px;
}

.feature-image {
  width: 34vw;
  max-width: 300px;
  border-radius: 12px;
  border: 1px solid rgba(88, 88, 88, 0.3);
  /* padding: 10px; */
}

.feature-card-content {
  overflow: hidden;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  color: white;
}

.feature-title {
  margin-top: 3vw;
  margin-bottom: 4vw;
}

/* .last-feature {
  color: black
} */

.full {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.snbd-info-section {
  display: flex;
  width: 84vw;
  max-width: 500px;
  justify-content: center;
  margin-bottom: 40px;
  color: rgb(92, 92, 92);
  /* background-color: red; */
}

.snbd-info-content {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  justify-content: center;
  gap: 0.1vw;
}

.folder {
  width: 30vw;
  max-width: 250px;
}

.folder-title {
  margin-top: 6px;
  margin-bottom: 6px;
  font-weight: 600;
  color: rgb(92, 92, 92);
}

.folder-description {
  color: rgb(124, 124, 124);
}

/* BOARD */

.board {
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(110px, 1fr));
  margin: 16px;
  margin-top: 67px;
  padding-top: 16px;
  gap: 10px
}

.sound-cell {
  position: relative;
  aspect-ratio: 0.84;
  cursor:pointer;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
}

.cell-content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 14px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.sound-cell-name {
  width: 100%; 
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  color: white;
}

.sound-emoji {
  font-size: 55px;
}

.sound-image {
  height: 65px;
  border-radius: 8px;
}

.sound-image-text {
  font-size: 16pt;
  font-weight: 500;
  color: white;
  width: 100%; 
  white-space: pre-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.row-transparent-background {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
}

.row-background {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
}

.animate {
  animation-timing-function: linear;
  animation-name: play;
}

.info-modal-container {
  z-index: 2;
  width: 100%;
  height: 100%;
  position: fixed;
  display: grid;
  align-items: center;
  justify-content: center;
}

.info-modal-background {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(87, 87, 87, 0.9);
  backdrop-filter: blur(1px);
}

.info-modal {
  background-color: white;
  z-index: 2;
  /* height: 1000px; */
  width: 660px;
  max-width: 97vw;
  border-radius: 10px;
  margin: 10px;
  /* height: 100%;
  overflow-y: scroll; */
}

.info-header {
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px; */
  border-bottom: 1px solid rgba(203, 203, 203, 0.4);
}

.info-content-container {
  max-height: 70vh;
  overflow-y: scroll;
}

.info-content {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  word-wrap: break-word;
  overflow-y: scroll;
}

.info-modal-board-sources {
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid rgba(203, 203, 203, 0.4);
}

.info-modal-row {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(203, 203, 203, 0.4);
  padding-top: 6px;
  padding-bottom: 6px;
}

/* .info-modal-row:first {
  border-top: 0px solid rgba(203, 203, 203, 0.4);
} */

.info-modal-sound-name {
  font-weight: 700;
}

.info-modal-source-row {
  display: flex;
  gap: 4px;
}

@keyframes play {
  0%   {width: 0%;}
  100% {width: 100%;}
}


/* PRIVACY POLICY */

.privacy-policy-page {
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.footer {
  display: flex;
  gap: 30px;
  padding-bottom: 30px;
}
